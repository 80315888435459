import {
  CellContentType,
  TableCategory,
  TableHeaders
} from '@/shared/components/table/models';
import moment from 'moment-timezone';
import { DATE_API_FORMAT } from '@/utils/date.util';
import { DropLiveType, LocationType } from '@/shared/models/woSchedule.model';
import { setPoolDisabled } from '@/pages/Dispatch/utils/set-chaiss-pool';
import { dropLiveOptions } from '@/pages/Dispatch/models/selectsOptions';
import { i18n } from '@/i18n';

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

function getTime(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('hh:mm')
    : schedule;
}

export const Categories1TableHeaders: TableHeaders[] = [
  {
    key: 'number',
    label: i18n.tc('mainTable.no'),
    tdClass: 'min-w-20'
  },
  {
    key: 'orderNumber',
    label: i18n.tc('mainTable.wo'),
    tdClass: 'min-w-100'
  },
  {
    key: 'billTo',
    label: i18n.tc('order.billTo'),
    tdClass: 'min-w-80'
  }
];

export const EquipmentTableHeaders: (pools) => TableHeaders[] = pools => [
  {
    key: 'containerNumber',
    label: i18n.tc('order.cont'),
    tdClass: 'min-w-80'
  },
  {
    key: 'shippingLine',
    rendererKey: 'equipment',
    label: i18n.tc('order.ssl'),
    tdClass: 'min-w-30'
  },
  {
    key: 'containerSize',
    rendererKey: 'equipment',
    label: i18n.tc('mainTable.sz'),
    collapsed: false,
    tdClass: 'min-w-30'
  },
  {
    key: 'chassisNumber',
    rendererKey: 'equipment',
    label: i18n.tc('order.chassisNumber'),
    collapsed: false,
    content: {
      type: CellContentType.Text,
      validators: { name: 'regular', value: '^[a-zA-Z]{4}[0-9]{6}$' },
      validatorMessage:
        'This field is a required and must have 4 letters and 6 digits'
    },
    onChange: item => setPoolDisabled(item, pools),
    tdClass: 'min-w-130'
  },
  {
    key: 'chassisPool',
    rendererKey: 'equipment',
    label: i18n.tc('order.chassisPool'),
    collapsed: false,
    content: {
      type: CellContentType.List,
      list: pools,
      validators: { name: 'requiredIf', value: 'chassisNumber' },
      validatorMessage: 'This field is a required'
    },
    tdClass: 'min-w-130'
  },
  {
    key: 'bondEntry',
    rendererKey: 'equipment',
    label: i18n.tc('mainTable.bond'),
    collapsed: false,
    tdClass: 'min-w-80'
  }
];

export const InfoTableHeaders: TableHeaders[] = [
  {
    key: 'referenceNumber',
    label: i18n.tc('mainTable.ref'),
    tdClass: 'min-w-80'
  },
  {
    key: 'poNumber',
    label: i18n.tc('mainTable.po'),
    rendererKey: 'info',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  {
    key: 'overHeight',
    label: i18n.tc('order.oh'),
    rendererKey: 'info',
    collapsed: false,
    content: {
      type: CellContentType.Checkbox
    },
    tdClass: 'min-w-40'
  },
  {
    key: 'other',
    label: i18n.tc('order.oth'),
    rendererKey: 'info',
    collapsed: false,
    content: {
      type: CellContentType.Checkbox
    },
    tdClass: 'min-w-40'
  }
];

export const BookingTableHeaders: TableHeaders[] = [
  {
    key: 'bookingNumber',
    label: i18n.tc('mainTable.booking'),
    tdClass: 'min-w-90'
  },
  {
    key: 'streetTurn',
    label: i18n.tc('order.st'),
    rendererKey: 'booking',
    collapsed: false,
    tdClass: 'min-w-90'
  },
  {
    key: 'erd',
    rendererKey: 'booking',
    label: i18n.tc('order.erd'),
    collapsed: false,
    tdClass: 'min-w-90'
  },
  {
    key: 'cutOff',
    label: i18n.tc('mainTable.co'),
    rendererKey: 'booking',
    collapsed: false,
    tdClass: 'min-w-90'
  }
];

export const LocationTableHeaders: TableHeaders[] = [
  {
    key: 'pulloutLocation',
    label: 'P/OUT',
    tdClass: 'min-w-80'
  },
  {
    key: 'deliveryLocation',
    label: 'DROP',
    rendererKey: 'loc',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  {
    key: 'returnLocation',
    label: 'RTN',
    rendererKey: 'loc',
    collapsed: false,
    tdClass: 'min-w-80'
  }
];

export const GeoTableHeaders: TableHeaders[] = [
  {
    key: 'originLocation',
    label: i18n.tc('mainTable.orig'),
    tdClass: 'min-w-80'
  },
  {
    key: 'currLocation',
    label: i18n.tc('mainTable.curr'),
    rendererKey: 'geo',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  {
    key: 'destLocation',
    label: i18n.tc('mainTable.dest'),
    rendererKey: 'geo',
    collapsed: false,
    tdClass: 'min-w-80'
  }
];

export const Categories2TableHeaders: TableHeaders[] = [
  {
    key: 'returnFreeDay',
    label: i18n.tc('order.rfd'),
    rendererKey: 'cat2',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  {
    key: 'returnFreeDayLeft',
    label: i18n.tc('mainTable.rfdDay'),
    collapsed: false,
    tdClass: 'min-w-80'
  }
];

export const ScheduleTableHeaders: TableHeaders[] = [
  {
    key: 'pulloutSchedule',
    label: i18n.tc('mainTable.pOutSDate'),
    rendererKey: 'sch',
    tdClass: 'min-w-130'
  },
  {
    key: 'deliverySchedule',
    label: i18n.tc('mainTable.dilvSDate'),
    rendererKey: 'sch',
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutScheduleTime',
    label: i18n.tc('mainTable.time'),
    rendererKey: 'sch',
    tdClass: 'min-w-130'
  },
  {
    key: 'dropLive',
    label: i18n.tc('mainTable.dropLive'),
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    content: {
      type: CellContentType.List,
      list: dropLiveOptions
    }
  },
  {
    key: 'loadConfirmation',
    label: i18n.tc('order.loadCFM')
  },
  {
    key: 'pickupSchedule',
    label: i18n.tc('mainTable.pUSDate'),
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getDay,
    content: {
      type: CellContentType.Date,
      skipValidation: true,
      isDisabledBy: DropLiveType.LIVE
    }
  },
  {
    key: 'pickupScheduleTime',
    label: i18n.tc('mainTable.time'),
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getTime,
    content: {
      type: CellContentType.Time,
      skipValidation: true,
      isDisabledBy: DropLiveType.LIVE
    }
  },
  {
    key: 'pickupDriver',
    label: i18n.tc('mainTable.driverId'),
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    content: {
      type: CellContentType.Driver,
      locationType: LocationType.PICKUP,
      date: 'pickupSchedule',
      time: 'pickupScheduleTime',
      skipValidation: true,
      isDisabledBy: DropLiveType.LIVE
    }
  },
  {
    key: 'returnSchedule',
    label: i18n.tc('mainTable.rtnSDate'),
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getDay,
    content: {
      type: CellContentType.Date,
      skipValidation: true
    }
  },
  {
    key: 'returnScheduleTime',
    label: i18n.tc('mainTable.time'),
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getTime,
    content: {
      type: CellContentType.Time,
      skipValidation: true
    }
  },
  {
    key: 'returnDriver',
    label: i18n.tc('mainTable.driverId'),
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    content: {
      type: CellContentType.Driver,
      locationType: LocationType.RETURN,
      date: 'returnSchedule',
      time: 'returnScheduleTime',
      skipValidation: true
    }
  }
];

export const Categories4TableHeaders: TableHeaders[] = [
  {
    key: 'save',
    label: i18n.tc('common.save'),
    content: {
      type: CellContentType.Button,
      label: i18n.tc('common.save')
    },
    tdClass: 'min-w-60'
  },
  {
    key: 'complete',
    label: i18n.tc('common.complete'),
    content: {
      type: CellContentType.Button,
      label: i18n.tc('common.complete')
    },
    tdClass: 'min-w-60'
  },
  {
    key: 'remarks',
    label: i18n.tc('order.remarks'),
    content: {
      type: CellContentType.Text,
      skipValidation: true
    }
  }
];

export const ReturnCategoriesHeaders: TableCategory[] = [
  {
    key: 'category1',
    label: ' ',
    maxColspan: Categories1TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'equipment',
    label: i18n.tc('mainTable.headers.equipment'),
    maxColspan: EquipmentTableHeaders([]).length,
    minColspan: 1,
    class: 'equipment-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'info',
    label: i18n.tc('mainTable.headers.info'),
    maxColspan: InfoTableHeaders.length,
    minColspan: 1,
    class: 'info-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'booking',
    label: i18n.tc('mainTable.booking'),
    maxColspan: BookingTableHeaders.length,
    minColspan: 1,
    class: 'booking-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'loc',
    label: i18n.tc('mainTable.headers.location'),
    maxColspan: LocationTableHeaders.length,
    minColspan: 1,
    class: 'location-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'geo',
    label: i18n.tc('mainTable.headers.geofence'),
    maxColspan: GeoTableHeaders.length,
    minColspan: 1,
    class: 'geofence-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'category2',
    label: '',
    maxColspan: Categories2TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'sch',
    label: i18n.tc('mainTable.headers.schedule'),
    maxColspan: ScheduleTableHeaders.length,
    minColspan: 1,
    class: 'schedule-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'category4',
    label: '',
    maxColspan: Categories4TableHeaders.length,
    minColspan: 1
  }
];

export const ReturnTableHeaders: (pools) => TableHeaders[] = pools => [
  ...Categories1TableHeaders,
  ...EquipmentTableHeaders(pools),
  ...InfoTableHeaders,
  ...BookingTableHeaders,
  ...LocationTableHeaders,
  ...GeoTableHeaders,
  ...Categories2TableHeaders,
  ...ScheduleTableHeaders,
  ...Categories4TableHeaders
];
